* {
  box-sizing: border-box;
  z-index: 100;
}

:root {
  --color-white: #fff;
  --color-red: #ff2c64;
  --color-dark-blu: #003b91;
  --color-blue: #55a5b9;
  --main-font: "PT Sans", sans-serif; // шрифт по умолчанию
  --color-green: #368a68; // цвет по умолчанию
  --main-font-size: 16px; // размер шрифта по умолчанию
  --color-grey: #616161;
  --color-violet: #9f449d;
  --color-rose: #fff2f5;
  --color-blue-w: #e6edff;
  --color-green-w: #f1f9f6;
  --color-violet-w: #faf3fa;
}

a {
  text-decoration: none !important;
  color: inherit;
}
sup {
  font-family: "Arial", sans-serif;
  font-style: normal;
  top: -0.8em;
  font-size: 0.5em;
}
.hidden {
  display: none;
}
.t282__container {
  display: none !important;
}
.t282__container.t282__positionstatic {
  display: none !important;
}
.t282__container__bg, .t282__container__bg_opened {
  display: none !important;
}
button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
a:hover {
  color: #fff;
}
.button-fixed {
  display: block;
    max-width: 370px;
    position: fixed;
    top: 4%;
    left: 5%;
    z-index: 300;
    background-color: #55a5b9 ;
    border-radius: 25px;
    color: #fff;
    font-weight: bold;
  font-size: 13px;
    padding: 12px 30px;
   
}

.plus_button {
  display: none;
  background: var(--color-orange);
  color: var(--color-white);
  font-weight: bold;
  font-size: 13px;
  padding: 12px 20px;
  border-radius: 24px;
  //max-width: 137px;
  margin: 0 auto 0;
}

html {
  background-color: var(--color-white);
}

.wrapper {
  overflow-x: hidden;
  background: var(--color-white);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

body {
  font-family: var(--main-font), sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: var(--main-font-size);
  color: var(--color-grey);
  .red {
    color: var(--color-red) !important;
  }
  .blue {
    color: var(--color-blue) !important;
  }
  .blue_d {
    color: var(--color-dark-blu) !important;
  }
  .green {
    color: var(--color-green) !important;
  }
  .violet {
    color: var(--color-violet) !important;
  }
}

img {
  max-width: 100%;
  display: block;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

.mob-scroll {
  overflow-x: scroll;
}
h2 {
  sup {
    font-style: normal;
    font-size: 26px;
  }
}
.lib_ttl {
  display: flex;
  margin-top: 24px;
  margin-bottom: 10px;
  .show_lib {
    cursor: pointer;
    position: relative;
    padding-top: 5px;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: #55a5b9;
    vertical-align: top;
    margin-left: 10px;
    &::after {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      right: -30px;
      top: 11px;
      background: url("/public/arrow_down.png");
      background-repeat: no-repeat;
      background-size: contain;
    }
    &.active {
      &::after {
        top: 3px;
        transform: rotateX(180deg);
      }
    }
  }
}
.or{
    position: relative;
    &::before{
        position:absolute;
        content: "Или";
    
        width: 60px;
        height: 60px;
       top: 20px;
        right: -30px;
        font-family: PT Sans;
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 36px;
color: #FF2C64;
    }
}
.red_arrow{
    position: relative;
    &::after{
        position:absolute;
        content: "";
        width: 180px;
        height: 34px;
        background-image: url(/public/red_arrow.svg);
        background-repeat: no-repeat;
        background-size: contain;
        top: -10px;
        right: -90px;
    }
}
.bar_index{
  margin-bottom: 80px;
}
.lib_list {
  &.disable {
    display: none;
  }
}
.viol_line {
  position: relative;
  margin-top: 5%;
}
.viol_line::before {
  content: "";
  position: absolute;
  background-image: url(/public/viol_line.svg);
  background-repeat: no-repeat;
  width: 80px;
  height: 384px;
  top: 5%;
  left: 5%;
}
.green_line {
  position: relative;
}
.green_line::before {
  content: "";
  position: absolute;
  background-image: url(/public/green_line.svg);
  background-repeat: no-repeat;
  width: 80px;
  height: 384px;
  top: 5%;
  left: 5%;
}
._m_bot {
  margin-bottom: 70px;
  margin-top: 5%;
}
._flex {
  ._m_top {
    margin-top: 40px;
  }
}
.mfp-hide {
  max-width: 1064px;
}
.medWarn__content {
  overflow: hidden;
  max-height: 0;
  transition: 1s;
  margin-top: 40px;
  h2{
    margin-bottom: 10px;
  }
  p{
    margin-top: 5px;
  }
}
.medWarn__more:hover {
  text-decoration: underline;
}
#popup_p0{
  height: auto;
}
#btn_overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}
.slider {
  display: flex;
  position: relative;
}
.slick-track {
  display: flex;
}
.slick-list {
  overflow: hidden;
}
.slider .slick-arrow {
  top: 45%;
  position: absolute;
  z-index: 100;
  font-size: 0;
  width: 40px;
  height: 40px;
  background-size: contain;
}
.slider .slick-arrow.slick-prev {
  left: -30px;

  background: url(/public/prev-arr.png) 0 0 / 100% no-repeat;
}
.slider .slick-arrow.slick-next {
  right: -50px;

  background: url(/public/next-arr.png) 0 0 / 100% no-repeat;
  background-repeat: no-repeat;
}
.slick-slide {
  padding: 15px;
}
.sld {
  margin: 0 auto;
  border: solid 1px;
  border-bottom: solid 2px;
  border-color: var(--color-blue);
  border-radius: 12px;
  width: 300px;
  .b_text {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 8px;
  } 
  .text_p {
    font-size: 20px;
    line-height: 26px;
  }
  .desc {
    text-align: left;
    height: 150px;
    padding: 20px;
  }
  .read {
    margin-right: 20px;
    position: relative;
    margin: 20px;
    display: flex;
    justify-content: flex-end;
    letter-spacing: 0.04em;
    p {
      padding-right: 30px;
      color: var(--color-blue);
      font-weight: bold;
      font-size: 20px;
      line-height: 20px;
    }
    &::after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      right: 0;
      top: 0px;

      background-repeat: no-repeat;
      background-image: url(/public/arrow_blue.png);
    }
  }
}

.slick-slide {
  padding: 15px;
  z-index: 100;
}
.sld {
  .img {
    transition: 0.5s;
    padding: 0px;
  }
  &:hover {
    .img {
      transition: 1s;
      padding: 20px;
    }
  }
  margin: 0 auto;
  border: solid 1px;
  border-bottom: solid 2px;
  border-color: var(--color-blue);
  border-radius: 12px;
  width: 300px;

  .desc {
    text-align: left;
    height: 150px;
    padding: 20px;
  }
  .read {
    margin-right: 20px;
    position: relative;
    margin: 20px;
    display: flex;
    justify-content: flex-end;
    p {
      padding-right: 30px;
      color: var(--color-blue);
      font-weight: bold;
      font-size: 20px;
      line-height: 20px;
    }
    &::after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      right: 0;
      top: 0px;

      background-repeat: no-repeat;
      background-image: url(/public/arrow_blue.png);
    }
  }
}
._flex {
  display: flex;
  align-items: center;
}
.b_text {
  font-weight: bold;
}
.buton {
  border: 1px solid #ff2c64;
  box-sizing: border-box;
  border-radius: 18px;
  text-align: center;
  cursor: pointer;
  p {
    padding: 3px;
    color: var(--color-red);
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
  }
}

.line_anim.play {
  stroke-dasharray: 1200;
  stroke-dashoffset: 1200;
  animation: dash 5s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
.line_anim2.play {
  stroke-dasharray: 1200;
  stroke-dashoffset: 1200;
  animation: dash 5s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
.grafik {
  svg {
    width: 100% !important;
    height: auto;
  }
}
.btn {
  -webkit-backface-visibility: hidden;
  &:hover {
  }
  &._1 {
    &:hover {
      transition: 1s;
      background: linear-gradient(
        180deg,
        #f3114d -16.91%,
        #f3114d 118.38%
      ) !important;
    }

    background: linear-gradient(180deg, #ff7b9e -16.91%, #ff2c64 118.38%);
    box-shadow: 0px 4px 0px #ff0749,
      inset 0px 0px 24px rgba(255, 176, 197, 0.24);
  }
  &._2 {
    &:hover {
      transition: 1s;
      background: linear-gradient(
        180deg,
        #291fb8 -16.91%,
        #2c2496 118.38%
      ) !important;
    }
    background: linear-gradient(180deg, #032c9d -17.65%, #021e6c 126.47%);
    box-shadow: 0px 4px 0px #021751, inset 0px 0px 24px rgba(108, 146, 252, 0.4);
  }
  &._3 {
    &:hover {
      transition: 1s;
      background: linear-gradient(
        180deg,
        #368a68 -16.91%,
        #368a68 118.38%
      ) !important;
    }
    background: linear-gradient(180deg, #54bc92 -17.65%, #368a68 116.18%);
    box-shadow: 0px 4px 0px #2f795b,
      inset 0px 0px 24px rgba(169, 221, 200, 0.24);
  }
  &._4 {
    &:hover {
      transition: 1s;
      background: linear-gradient(
        180deg,
        #bf6abe -16.91%,
        #bf6abe 118.38%
      ) !important;
    }
    background: linear-gradient(180deg, #bf6abe -13.24%, #9f449d 116.91%);
    box-shadow: 0px 4px 0px #8b3c89,
      inset 0px 0px 24px rgba(223, 181, 222, 0.24);
  }
  &._5 {
    &:hover {
      transition: 1s;
      background: #616161 !important;
      p {
        color: #ffffff !important;
      }
    }
    background: #ffffff;
    box-shadow: 0px 4px 0px #e4f1f6;
    border-radius: 80px;
    p {
      color: #616161 !important;
    }
  }
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 48px;

  &.v2 {
    width: 367px;
    padding: 24px 30px;
  }
  width: 212px;
  height: 68px;
  p {
    font-weight: bold !important;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 0 !important;
  }

  border-radius: 80px;
}
.t_w {
  color: #fff;
}
h1 {
  padding-top: 76px;
  font-style: normal;
  font-weight: bold;
  font-size: 80px;
  line-height: 86px;
  color: var(--color-grey);
  sup {
    font-size: 30px;
    top: -1em;
  }
}
.section {
  max-width: 100%;
  width: 100%;
  position: relative;
  &._1 {
    padding-bottom: 80px;
    background-image: url(/public/wave_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    .content {
      padding-top: 0;
    }
    .logo {
      margin-top: 40px;
    }
  }
  .left {
    position: relative;
  }
  .rus_logo {
    position: absolute;
    right: -5px;
    bottom: 110px;
  }
  .right {
    img {
      margin-left: 30px;
    }
  }

  &._2 {
    background-image: url(/public/bg_sec2.png);
    background-size: cover;
    background: #f2f8fa;
    border-radius: 48px;
    h2 {
      margin-top: 80px;
      max-width: 70%;
      text-align: center;
      color: var(--color-blue);
      font-style: normal;
      font-weight: bold;
      font-size: 46px;
      line-height: 52px;
    }
    .info {
      margin-top: 80px;
    }
    .radial {
      z-index: 0;
      position: relative;
      display: flex;
      justify-content: center;
      vertical-align: middle;
      width: 37%;
      img {
        width: 150%;
        margin-top: -100px;
        position: absolute;
      }
    }
    ._deb {
      justify-content: center;
    }
    ._deb_left {
      margin-right: -80px;
    }
    ._deb_bot {
      margin-top: -200px;
    }
    ._deb_right {
      margin-right: -80px;
    }

    .b_info {
      display: flex;

      margin-bottom: 80px;

      color: var(--color-grey);
      .b_text {
        font-weight: bold;
        font-size: 26px;
        line-height: 32px;
      }

      .text_p {
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
      }
      .text {
        width: 70%;
      }
      position: relative;

      .icon {
        display: flex;
        vertical-align: middle;
        justify-content: center;
        position: relative;
        width: 130px;
        padding: 20px;
      }
      &.left {
        flex-direction: row-reverse;
        text-align: right;
        margin-right: -80px;
        margin-top: -20px;
        .icon {
          margin-left: 20px;
        }
      }
      &.right {
        margin-left: -80px;
        text-align: left;
        margin-top: 15px;

        .icon {
          margin-right: 20px;
        }
      }
      &._1 {
        img {
          top: -10px;
        }
      }
      &._2 {
        .icon {
          margin-bottom: 120px;
        }
        img {
        }
      }
      &._5 {
        margin-left: 0;
        margin-bottom: 40px;
        display: block;
        margin-top: 0;
        .text {
          width: 100%;
        }
        .icon {
          width: auto;
          justify-content: center;
          height: 120px;
        }
      }
    }
    .b2 {
      margin-top: 20px;
      margin-bottom: 40px;
      background-image: url(/public/bg_sec2.png);
      height: 164px;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 12px;
      p {
        padding-top: 40px;

        font-weight: bold;
        font-size: 34px;
        line-height: 40px;
        text-align: center;
      }
    }
  }
  &._3 {
    background-image: url(/public/wave_bg.png);
    padding-top: 80px;
    background-size: cover;
    margin-bottom: 100px;

    .prep {
      height: 300px;
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 80px;
      position: relative;
      border-radius: 12px;
      .ttl {
        margin-top: 20px;
        margin-left: 20px;
        font-weight: bold;
        font-size: 32px;
        line-height: 36px;
      }
      .text {
        margin-left: 20px;
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
        margin-top: 20px;
        margin-bottom: 60px;
      }
      img {
        position: absolute;
        right: 20px;
        top: -40px;
        max-width: 265px;
      }
      .btn {
        position: absolute;
        right: 30px;
        bottom: 30px;
      }
      &._1 {
        background-color: var(--color-rose);
        .ttl {
          color: var(--color-red);
        }
        .btn {
          background: linear-gradient(180deg, #ff7b9e -16.91%, #ff2c64 118.38%);
          box-shadow: 0px 4px 0px #ff0749,
            inset 0px 0px 24px rgba(255, 176, 197, 0.24);
        }
      }
      &._2 {
        background-color: var(--color-blue-w);
        .ttl {
          color: var(--color-dark-blu);
        }
      }
      &._3 {
        background-color: var(--color-green-w);
        .ttl {
          color: var(--color-green);
        }
      }
      &._4 {
        background-color: var(--color-rose);
        .ttl {
          color: var(--color-red);
        }
      }
    }
  }
  &._4 {
    .text {
      font-weight: normal;
      font-size: 22px;
    }
  }
  &._6 {
    margin-bottom: 80px;
    a:hover {
      color: #616161;
  }
    h2 {
      margin-top: 80px;
      color: var(--color-blue);
      font-style: normal;
      font-weight: bold;
      font-size: 46px;
      line-height: 52px;
      padding-bottom: 24px;
    }
    .blue_line {
      margin-bottom: 24px;
    }
  }

  &._7 {
    background-image: url(../public/footer_bg.png);
    background-color: #fafafa;
    border-radius: 20px;
    .line_border {
      margin-top: 40px;
      margin-bottom: 20px;
    }
    .libr {
      margin-top: 40px;
      .b_text {
        font-size: 20px;
        line-height: 30px;
      }
    }
    .footer {
      margin-top: 40px;
      .b_text {
        font-size: 20px;
        line-height: 30px;
      }
      .col-md-12 {
        margin-top: 10px;
      }

      .cont {
        .box {
          display: flex;
          flex-direction: row;
          .icon {
            margin-right: 5px;
            margin-top: 2px;
          }
        }
      }
    }
    .footer_2 {
      margin-top: 40px;
      margin-bottom: 40px;
      p {
        font-weight: normal;
        font-size: 25px;
        line-height: 28px;
        /* or 112% */

        text-align: center;
        letter-spacing: -0.01em;
        text-transform: uppercase;
      }
    }
  }

  & .content {
    max-width: 1280px;
    margin: 0 auto;
  }
}

.nav {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  justify-content: center;
  padding-top: 24px;
  p {
    color: var(--color-grey);
  }
  &.active {
    &._1 {
      border-top: solid 4px;
      border-color: var(--color-red);
      p {
        color: var(--color-red);
      }
    }
    &._2 {
      border-top: solid 4px;
      border-color: var(--color-dark-blu);
      p {
        color: var(--color-dark-blu);
      }
    }
    &._3 {
      border-top: solid 4px;
      border-color: var(--color-green);
      p {
        color: var(--color-green);
      }
    }
    &._4 {
      border-top: solid 4px;
      border-color: var(--color-violet);
      p {
        color: var(--color-violet);
      }
    }
  }
}
.m_text {
  font-size: 34px;
  line-height: 35px;

  text-align: center;
}
.m-w {
  max-width: 266px;
  span {
    font-weight: bold;
  }
}
.b_text {
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
}
.p1 {
    .icon_list .pic._2 {
        margin-top: -70px;
       
    }
 

    .list_icon{
        margin-bottom: 50px;
    }
  .popup_p1_1 {
    max-width: 1064px !important;
    height: auto;
  }
  .rose_block {
    background-image: url(/public/rose_bg_p1.png);
    background-size: cover;
    border-radius: 20px;
    padding: 24px 40px 40px 40px;
  }
  .rose_wave {
    margin: 24px auto;
  }
  h2 {
    margin-top: 16px;
    font-weight: bold;
    font-size: 46px;
    line-height: 52px;
  }
  .section {
    &._1 {
      background-image: url(/public/wave_3.png);
      background-size: cover;

      .p_info {
        margin-top: 16px;
        font-size: 20px;
        line-height: 28px;
      }
      .img_1 {
        margin-top: 40px;
        justify-content: center;
        img {
          width: 100%;
        }
      }
      .icon_list {
        margin-top: -7%;
        margin-bottom: 80px;
        .list_icon {
          display: flex;
          flex-direction: row;
          position: relative;
        }
        .pic {
          &:hover {
            transition: 0.3s;
            transform: scale(1.1);
          }
          padding-left: 20px;
          padding-right: 20px;
          &._1 {
            margin-top: -190px;
          }
       
          &._3 {
            margin-top: -35px;
          }
          text-align: center;
          width: 20%;
          .img {
            display: flex;
            justify-content: center;
          }
          p {
            margin-top: 12px;
          }
        }
      }
      .block_inf {
        padding: 30px;
        &._1 {
          background-image: url(/public/p1_block_1.png);
          background-size: cover;
          height: 332px;
          border-radius: 20px;
          background-repeat: no-repeat;
          margin-right: 20px;
          p {
            color: white;
          }
        }
        &._2 {
          background-image: url(/public/p1_block_2.png);
          background-size: cover;
          border-radius: 20px;
          background-repeat: no-repeat;
          margin-left: 20px;
          height: 332px;
          .btn {
            margin-top: 40px;
          }
        }
        .ttl {
          margin-bottom: 20px;
          font-weight: bold;
          font-size: 46px;
          line-height: 52px;
        }
        p {
          font-weight: normal;
          font-size: 22px;
          line-height: 30px;
          margin-bottom: 10px;
        }
      }
    }
    &._2 {
        h2{
            margin-top: 40px;
        }
      background-image: url(/public/rose_bg.png);
      .sposob {
        text-align: center;
    
      }
      .img {
        display: flex;
        justify-content: center;
      }
      p {
        margin-top: 32px;
        font-weight: bold;
        font-size: 22px;
        line-height: 30px;
      }
      .anim {
        margin-top: 86px;
        margin-bottom: 40px;
      }
    }
    &._3 {
        padding-top: 40px;
      background: none;
      margin-bottom: 40px;
      h2 {
        margin-bottom: 24px;
      }
      .centr_img {
        margin-top: 20px;
        margin-left: -20px;
        max-width: 120%;
      }
      .b_info {
        margin-top: 30px;

        .b_text {
          margin-bottom: 10px;
          font-size: 32px;
        }
        .text {
          font-size: 22px;
        }
        img {
          width: 80px;
          margin-bottom: 20px;
        }
        &.right {
          text-align: right;
          .img {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
      .line_amoxi {
        margin-top: 3%;
      }
      .blue_line {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
    &._4 {
      background-image: url(/public/blue_bg.png);
      background-size: cover;
      background-repeat: no-repeat;
      padding-top: 20px;
      border-radius: 50px;
      
      .tabl {
        margin-top: 80px;
        margin-bottom: 80px;
      }
      .block_info {
        text-align: left;
        h2 {
          text-align: left;
          margin-bottom: 20px;
        }
        .btn {
          margin-top: 40px;
          margin-bottom: 40px;
        }
      }
      .b_inf {
        background-image: url(/public/bg_sec2.png);
        padding: 40px;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 20px;
        margin-top: 40px;
        p {
          font-weight: bold;
          font-size: 28px;
          line-height: 32px;
          color: #ffffff;
          text-align: center;
        }
      }
    }
    &._5 {
      .rose_block {
        background-image: url(/public/bg_rose1.png);
        padding: 40px;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 20px;
        margin-bottom: 80px;

        margin-top: 40px;
        h2 {
          text-align: left;
          margin-bottom: 40px;
        }
        .inf {
          margin-top: 24px;
          p {
            font-weight: normal;
            font-size: 20px;
            line-height: 28px;
          }
          .li {
            margin-top: 5px;
            position: relative;
            padding-left: 20px;
            font-weight: bold;
            font-size: 22px;
            line-height: 30px;
            &::before {
              content: "";
              height: 8px;
              width: 8px;
              position: absolute;
              left: 0;
              top: 12px;
              background-image: url(/public/point_red.png);
              background-size: cover;
              background-repeat: no-repeat;
            }
          }
        }
        .btn {
          margin-top: 24px;
        }
        .rose_wave {
          margin-top: 24px;
        }
        .img {
          display: flex;
          justify-content: center;
          &.left {
            margin-left: 20px;
          }
        }
      }
    }
    &._6 {
      margin-bottom: 80px;
    }

    &._7 {
      background-image: url(../public/footer_bg.png);
      background-color: #fafafa;
      border-radius: 20px;
      ._p_2 {
        padding-top: 24px;
      }
      .line_border {
        margin-top: 40px;
        margin-bottom: 20px;
      }
      .libr {
        margin-top: 40px;
        .b_text {
          font-size: 20px;
          line-height: 30px;
        }
      }
      .footer {
        margin-top: 40px;
        .b_text {
          font-size: 20px;
          line-height: 30px;
        }
        .col-md-12 {
          margin-top: 10px;
        }

        .cont {
          .box {
            display: flex;
            flex-direction: row;
            .icon {
              margin-right: 5px;
              margin-top: 2px;
            }
          }
        }
      }
      .footer_2 {
        margin-top: 40px;
        margin-bottom: 40px;
        p {
          font-weight: normal;
          font-size: 25px;
          line-height: 28px;
          /* or 112% */

          text-align: center;
          letter-spacing: -0.01em;
          text-transform: uppercase;
        }
      }
    }
  }
}
.p1 {
  .section {
    .block_inf {
      &._1 {
        position: relative;
      }
      &._1::after {
        content: "";
        background-image: url(/public/pill.svg);
        position: absolute;
        width: 204px;
        height: 212px;
        top: -28%;
        right: 0;
      }
    }
  }
}
.p2.p1 {
  
  .section {
    &._1 {
      .page_2_pack {
        max-width: 994px;
        margin: auto;
      }
      .icon_list {
        margin-top: -6%;
        .pic {
            
          width: 25%;
          &._2 {
            margin-top: -50px;
          }
        }
      }
      .block_inf {
        height: 262px;
        &._3 {
          margin-top: 40px;
          height: auto;
          background-image: url(/public/p2_rose_bg.png);
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 20px;
          display: flex;
          justify-content: space-between;

          .but {
            display: flex;
            align-items: center;
          }
          .text {
            max-width: 600px;
            margin-left: 250px;
          }
        }
        &._2 {
          background: #e4f1f6;
          .icon {
            margin-bottom: 5px;
          }
        }
        &._1 {
          position: relative;
        }
        &._1::after {
          content: "";
          background-image: url(/public/pill.svg);
          position: absolute;
          width: 204px;
          height: 212px;
          top: -28%;
          right: 0;
        }
      }
    }
    &._2 {
      .sposob_v2 {
        .text {
          h2 {
            text-align: left;
          }
          p {
            text-align: left;
            font-weight: bold;
            font-size: 32px;
            line-height: 36px;
          }
        }
      }
      .img_right {
        margin-top: 60px;
      }
      .rose_wave {
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .box {
        display: flex;
        .icon {
          margin-right: 20px;
        }
        .text {
          display: flex;
        }
        p {
          align-self: center;
          margin-top: 0;
          font-size: 22px;
          line-height: 30px;
        }
      }
    }
    &._4 {
      background-image: url(/public/blue_bg_p2.png);
      background-size: cover;
      background-repeat: no-repeat;
      padding-top: 20px;
      margin-bottom: 40px;

      .h1 {
        text-align: center;
        margin-bottom: 20px;
      }
    }
    &._5 {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    &._7 {
      ._p_2 {
        padding-top: 24px;
      }
    }
  }
}
._mar_star {
  margin-top: 77px;
  margin-bottom: 20px;
}
.p3.p2.p1 {
  .section {
    &._1 {
      ._flex {
        p {
          font-weight: bold;
          font-size: 22px;
          line-height: 30px;
        }
        .logo_g {
          margin-right: 24px;
        }
        &._1 {
          margin-bottom: 36px;
        }
        &._2 {
          margin-bottom: 36px;
          margin-left: 32px;
        }
      }
      .icon_list {
        margin-top: 20px;
        .pic {
          width: 25%;
        }
      }
      .block_inf {
        height: auto;
        margin-right: 0px;
        ._m_top {
          margin-top: 5%;
        }
        &._3 {
          margin-top: 40px;
          background-image: url(/public/p2_rose_bg.png);
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 20px;
          display: flex;
          justify-content: space-between;

          .but {
            padding-top: 40px;
            vertical-align: middle;
          }
          .text {
            max-width: 600px;
            margin-left: 250px;
          }
        }
        &._5 {
          background-image: url(/public/p3_bg_rose.png);
          background-size: cover;

          border-radius: 20px;
          background-repeat: no-repeat;
          margin-right: 0px;
          p {
          }
        }
        &._2 {
          background: #e4f1f6;
          .icon {
            margin-bottom: 5px;
          }
        }
      }
    }
    &._2 {
      .sposob_v2 {
  
      }
      .img_right {
        margin-top: 150px;
      }
      .rose_wave {
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .box {
        display: flex;
        margin-top: 15px;
        .icon {
          margin-right: 20px;
        }
        .text {
          display: flex;
        }
        p {
          align-self: center;
          margin-top: 0;
          font-size: 22px;
          line-height: 30px;
        }
      }
    }
    &._4 {
      background-image: url(/public/blue_bg_p2.png);
      background-size: cover;
      background-repeat: no-repeat;
      margin-top: 80px;
      .tabl {
      }
    }
    &._7 {
      ._p_2 {
        padding-top: 24px;
      }
    }
  }
}
.p3.p2.p1.p4 {
  .section {
    &._3 {
      h2 {
        text-align: left;
      }
      .img.left {
        padding: 40px;
      }
    }
  }
}

.white-pink {
  position: relative;
  border-radius: 12px;
  padding: 37px 48px;
  width: auto;
  max-width: 1064px;
  margin: 20px auto;
  li {
    font-weight: bold;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    color: #616161;
    margin-bottom: 12px;
  }
  .mfp-close {
    top: -17px;
    right: -17px;
    opacity: 100;
    background: linear-gradient(180deg, #ff7b9e -16.91%, #ff2c64 118.38%);
    box-shadow: 0px 4px 0px #ff0749,
      inset 0px 0px 24px rgba(255, 176, 197, 0.24);
    border-radius: 80px;
    color: #fff;
  }
}
.popup_p2_1 {
  background: #fff2f5;
  background-image: url(/public/popup_p2_1.png);

  background-size: cover;
  background-position-y: bottom;
  .ttl {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 46px;
    line-height: 52px;
  }
  .nose {
    margin-top: 32px;
    margin-bottom: 20px;
  }
  .b_text {
    font-size: 28px;
    line-height: 32px;
    padding-bottom: 12px;
  }
  .s_text {
    font-size: 22px;
    line-height: 30px;
    padding-bottom: 8px;
  }
}
.popup_p1_1 {
  background: #fff2f5;
  background-image: url(/public/popup_p1_1.png);
  background-size: cover;
  background-position-y: bottom;
  .pink_line {
    padding: 10px 0 32px;
  }
  ul li::marker {
    color: #ff2c64;
    font-size: 1em;
  }
  .ttl {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 46px;
    line-height: 52px;
  }
  .nose {
    margin-top: 32px;
    margin-bottom: 20px;
  }
  .b_text {
    font-size: 28px;
    line-height: 32px;
    padding-bottom: 12px;
  }
  .s_text {
    font-size: 22px;
    line-height: 30px;
    padding-bottom: 8px;
  }
}
.popup_p0_1 {
  position: absolute;
  padding: 70px;
  z-index: 10001;
  top: 20%;
  left: 0;
  right: 0;
  height: 100%;
  background-image: url(/public/popup_p0.png);
  background-size: cover;
  background-position-y: bottom;
  background-position: center;
  max-width: 1064px;
  height: 618px;
  .b_text {
    font-weight: bold;
  }
  .buton {
    border: 1px solid #ff2c64;
    box-sizing: border-box;
    border-radius: 18px;
    text-align: center;
    cursor: pointer;
    p {
      padding: 3px;
      color: var(--color-red);
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      text-transform: uppercase;
    }
  }
  label {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    color: #ff2c64;
   
  }
  .b_text {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    color: #616161;
    padding-top: 60px;
    padding-bottom: 65px;
    span {
      color: #1f1f1f;
    }
  }
  .ttl {
    font-family: PT Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 46px;
    line-height: 52px;
    color: #616161;
  }
  .custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .custom-checkbox + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
  }
  .custom-checkbox + label::before {
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #ff2c64;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    margin-right: 30px;
  }
  .custom-checkbox:checked + label::before {
    border-color: #ff2c64;
    background-color: #ff2c64;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }
  /* стили при наведении курсора на checkbox */
  .custom-checkbox:not(:disabled):not(:checked) + label:hover::before {
    border-color: #ff7b9e;
  }
  /* стили для активного состояния чекбокса (при нажатии на него) */
  .custom-checkbox:not(:disabled):active + label::before {
    background-color: #ff7b9e;
    border-color: #ff7b9e;
  }
  /* стили для чекбокса, находящегося в фокусе */
  .custom-checkbox:focus + label::before {
    box-shadow: 0 0 0 0.2rem rgba(255, 176, 197, 0.24);
  }
  /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
  .custom-checkbox:focus:not(:checked) + label::before {
    border-color: #ff7b9e;
  }
  /* стили для чекбокса, находящегося в состоянии disabled */
  .custom-checkbox:disabled + label::before {
    background-color: #e9ecef;
  }
}
.popup_p1_2 {
  background-image: url(/public/popup_p1_2.png);
  background-size: cover;
  background-position-y: bottom;

  .blue_line {
    margin-top: 14px;
  }
  .popup_table_1 {
    margin-top: 24px;
    margin-bottom: 80px;
  }
  .pink_line {
    padding: 40px 0 32px;
  }
  .ttl {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 46px;
    line-height: 52px;
  }
  .nose {
    margin-top: 32px;
    margin-bottom: 20px;
  }
  .b_text {
    font-size: 28px;
    line-height: 32px;
    padding-bottom: 12px;
  }
  .s_text {
    font-size: 22px;
    line-height: 30px;
    padding-bottom: 8px;
  }
  &.p_3,
  &.p_4 {
    max-width: 1064px;
    .b_text {
      font-weight: normal;
      font-size: 22px;
      line-height: 30px;
      color: #616161;
    }
    .ttl {
      font-weight: bold;
      font-size: 32px;
      line-height: 36px;
      color: #55a5b9;
    }
  }
}
@media screen and(max-width: 1660px) {
  .button-fixed {
    top: 10%;
  }
}
@media screen and(max-width: 1360px) {
  .content {
    padding: 40px;
  }
  .section {
    &._1 {
      .rus_logo {
        position: absolute;
        right: -5px;
        bottom: 110px;
        max-width: 140px;
      }
      .right {
        img {
          max-width: 490px;
          margin-left: 60px;
        }
      }
    }
    &._2 {
      ._deb_bot {
        margin-top: -240px;
      }
      .b_info {
        &.left {
          flex-direction: row-reverse;
          text-align: right;
          margin-right: -80px;
          margin-top: -40px;
        }
        &.right {
          margin-left: -80px;
          text-align: left;
          margin-top: -25px;
        }
      }
    }
  }
}
@media (max-width: 1280px) {
  h1 {
    font-size: 60px;
    line-height: 66px;
    sup {
      font-size: 30px;
      top: -1em;
    }
  }
  .slider {
    display: flex;
    position: relative;
  }
  .slick-track {
    display: flex;
  }
  .slick-list {
    overflow: hidden;
  }
  .slider .slick-arrow {
    top: 45%;
    position: absolute;
    z-index: 1000;
    font-size: 0;
    width: 40px;
    height: 40px;
    background-size: contain;
  }
  .slider .slick-arrow.slick-prev {
    left: -30px;
    background: url(/public/prev-arr.png) 0 0 / 100% no-repeat;
  }
  .slider .slick-arrow.slick-next {
    right: -45px;

    background: url(/public/next-arr.png) 0 0 / 100% no-repeat;
    background-repeat: no-repeat;
  }
  .slick-slide {
    padding: 15px;
    z-index: 100;
  }
  .sld {
    .img {
      transition: 0.5s;
      padding: 0px;
    }
    margin: 0 auto;
    border: solid 1px;
    border-bottom: solid 2px;
    border-color: var(--color-blue);
    border-radius: 12px;
    width: 300px;

    .desc {
      text-align: left;
      height: 150px;
      padding: 20px;
    }
    .read {
      margin-right: 20px;
      position: relative;
      margin: 20px;
      display: flex;
      justify-content: flex-end;
      p {
        padding-right: 30px;
        color: var(--color-blue);
        font-weight: bold;
        font-size: 20px;
        line-height: 20px;
      }
      &::after {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        right: 0;
        top: 0px;

        background-repeat: no-repeat;
        background-image: url(/public/arrow_blue.png);
      }
    }
  }
  .p1 {
    .section {
      max-width: 100%;
      width: 100%;
      position: relative;
      &._1 {
        padding-bottom: 80px;
        background-image: url(/public/wave_bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        .content {
          padding-top: 0;
        }
        .logo {
          margin-top: 40px;
        }

        .left {
          position: relative;
        }
        .rus_logo {
          position: absolute;
          right: -5px;
          bottom: 110px;
          max-width: 140px;
        }
        .right {
          img {
            max-width: 490px;
            margin-left: 60px;
          }
        }
      }
      &._2 {
        h2 {
          margin-top: 20px;
          max-width: 70%;
          text-align: center;
          color: var(--color-blue);
          font-style: normal;
          font-weight: bold;
          font-size: 46px;
          line-height: 52px;
        }
        .info {
          margin-top: 80px;
        }
        .radial {
          z-index: 0;
          position: relative;
          display: flex;
          justify-content: center;
          vertical-align: middle;
          width: 37%;
          img {
            width: 150%;
            margin-top: -100px;
            position: absolute;
          }
        }
        ._deb {
          justify-content: center;
        }
        ._deb_left {
          margin-right: -80px;
        }
        ._deb_bot {
          margin-top: -200px;
        }
        ._deb_right {
          margin-right: -80px;
        }

        .b_info {
          display: flex;

          margin-bottom: 80px;

          color: var(--color-grey);
          .b_text {
            font-weight: bold;
            font-size: 26px;
            line-height: 32px;
          }

          .text_p {
            font-weight: normal;
            font-size: 20px;
            line-height: 30px;
          }
          .text {
            width: 70%;
          }
          position: relative;

          .icon {
            display: flex;
            vertical-align: middle;
            justify-content: center;
            position: relative;
            width: 120px;
            padding: 20px;
          }
          &.left {
            flex-direction: row-reverse;
            text-align: right;
            margin-right: -80px;
            .icon {
              margin-left: 20px;
            }
          }
          &.right {
            margin-left: -80px;
            text-align: left;

            .icon {
              margin-right: 20px;
            }
          }
          &._1 {
            img {
              top: -10px;
            }
          }
          &._2 {
            .icon {
              margin-bottom: 120px;
            }
            img {
            }
          }
          &._5 {
            margin-left: 0;
            margin-bottom: 40px;
            display: block;
            margin-top: -10px;
            .text {
              width: 100%;
            }
            .icon {
              width: auto;
              justify-content: center;
              height: 110px;
            }
          }
        }
        .b2 {
          margin-top: 20px;
          margin-bottom: 40px;
          background-image: url(/public/bg_sec2.png);
          height: 164px;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 12px;
          p {
            padding-top: 40px;

            font-weight: bold;
            font-size: 34px;
            line-height: 40px;
            text-align: center;
          }
        }
      }
      &._3 {
        background-image: url(/public/wave_bg.png);
        padding-top: 80px;
        background-size: cover;
        margin-bottom: 100px;
        .prep {
          height: 300px;
          margin-left: 20px;
          margin-right: 20px;
          margin-top: 80px;
          position: relative;
          border-radius: 12px;
          .ttl {
            margin-top: 20px;
            margin-left: 20px;
            font-weight: bold;
            font-size: 32px;
            line-height: 36px;
          }
          .text {
            margin-left: 20px;
            font-weight: normal;
            font-size: 22px;
            line-height: 30px;
            margin-top: 20px;
            margin-bottom: 60px;
          }
          img {
            position: absolute;
            right: 20px;
            top: -40px;
            max-width: 250px;
          }
          .btn {
            position: absolute;
            right: 30px;
            bottom: 30px;
          }
          &._1 {
            background-color: var(--color-rose);
            .ttl {
              color: var(--color-red);
            }
            .btn {
              background: linear-gradient(
                180deg,
                #ff7b9e -16.91%,
                #ff2c64 118.38%
              );
              box-shadow: 0px 4px 0px #ff0749,
                inset 0px 0px 24px rgba(255, 176, 197, 0.24);
            }
          }
          &._2 {
            background-color: var(--color-blue-w);
            .ttl {
              color: var(--color-dark-blu);
            }
          }
          &._3 {
            background-color: var(--color-green-w);
            .ttl {
              color: var(--color-green);
            }
          }
          &._4 {
            background-color: var(--color-rose);
            .ttl {
              color: var(--color-red);
            }
          }
        }
      }
      &._6 {
        margin-bottom: 80px;
      }

      &._7 {
        background-image: url(../public/footer_bg.png);
        background-color: #fafafa;
        border-radius: 20px;
        .line_border {
          margin-top: 40px;
          margin-bottom: 20px;
        }
        .libr {
          margin-top: 40px;
          .b_text {
            font-size: 20px;
            line-height: 30px;
          }
        }
        .footer {
          margin-top: 40px;
          .b_text {
            font-size: 20px;
            line-height: 30px;
          }
          .col-md-12 {
            margin-top: 10px;
          }

          .cont {
            .box {
              display: flex;
              flex-direction: row;
              .icon {
                margin-right: 5px;
                margin-top: 2px;
              }
            }
          }
        }
        .footer_2 {
          margin-top: 40px;
          margin-bottom: 40px;
          p {
            font-weight: normal;
            font-size: 25px;
            line-height: 28px;
            /* or 112% */

            text-align: center;
            letter-spacing: -0.01em;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
@media (max-width: 1250px) {
  .p1 {
  
    .section {
      &._1 {
        .icon_list {
          margin-top: -5%;
          .pic {
         &._1 {
           margin-top: -150px;
         }
         &._2{
          margin-top: -60px;
        }
          }
        }
      }
    }
  }
  .p2.p1 {
  
    .section {
      &._1 {
        .icon_list {
          margin-top: 0%;
          .pic {
         &._1 {
           margin-top: -130px;
         }
         &._2{
          margin-top: -50px;
        }
          }
        }
      }
    }
  }
  .p3.p2.p1 .section._1 .block_inf {
    height: 402px;
}
  .p1 .section._1 .block_inf._2 {
    height: 362px;
}
.p1 .section._1 .block_inf._1 {
  height: 362px;
}
  .p1 {
    .section {
      &._2 {
        ._deb_bot {
          margin-top: -260px;
        }
        .b_info {
          &._2 {
            .icon {
              margin-bottom: 160px;
            }
          }
          &.right {
            margin-left: -80px;
            text-align: left;
            margin-bottom: 0;
            &._2 {
              margin-top: 0;
              .icon {
                margin-bottom: 220px;
              }
            }
          }
        }
      }
      &._3 {
        .prep {
          .text {
            margin-top: 10px;
            line-height: 26px;
          }
          .ttl {
            font-size: 26px;
            line-height: 28px;
          }
          img {
            max-width: 200px;
          }
        }
      }
      &._4 {
        .text {
          line-height: 26px;
        }
      }
    }
  }
}
@media (max-width: 1100px) {
  .p4.p3.p2.p1 .section._1 .block_inf {
    height: 452px;
}
  .p3.p2.p1 .section._1 .block_inf {
    height: 452px;
}
  .section._3 .prep .ttl {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
    font-size: 28px;
    line-height: 30px;
}
  .section._3 .prep .text {
    margin-left: 20px;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    margin-top: 10px;
    margin-bottom: 60px;
}
  .section._3 .prep img {
    max-width: 165px;
}
  .p0 {
    .col-lg-4 {
      flex: 0 0 auto;
      width: 36.33333333%;
    }
  }
  .p1 {
    .section {
      &._1 {
        .right {
          img {
            max-width: 400px;
            margin-left: 30px;
          }
        }
        .rus_logo {
          max-width: 100px;
        }
      }
      &._2 {
        .b2 {
          p {
            padding-top: 20px;
          }
        }
        ._deb_bot {
          margin-top: -360px;
        }
        .b_info {
          &._2 {
            .icon {
              margin-bottom: 260px;
            }
          }
          &.left {
            margin-top: -50px;
          }
          &.right {
            &._2 {
              .icon {
                margin-bottom: 350px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1070px) {
  .mfp-hide {
    max-width: 90%;
  }
 
}
@media (max-width: 1000px) {
  .p3.p2.p1 .section._1 .block_inf {
    height: 522px;
}
  .p1 .section._3 {
    margin-bottom: 10px;
  }
  .p3.p2.p1 .section._4 {
    margin-top: 0px;
  }
  .viol_line {
    margin-top: 20px;
  }
  .p3.p2.p1 .section._1 .plash {
    margin-top: 0px;
  }
  .logo_g {
    max-width: 80px;
  }
  .p2.p1 .section._5 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .p1 .section._6 {
    margin-bottom: 10px;
  }
  .p1 {
    .section {
      &._1 {
        background-size: 200%;
        background-position-y: 25%;
      }
      &._4 {
        .tabl {
          margin-top: 0;
          margin-bottom: 20px;
        }
      }
    }
  }
  .p2.p1 .section._2 .box {
    margin-bottom: 10px;
  }
  
  .box_text {
    max-width: 80%;
  }
  .p1 .section._1 .icon_list {
    margin-bottom: 0px;
  }
  .p1 .section._1 .block_inf._1::after {
    content: none;
  }
  .p2.p1 .section._1 .block_inf._1::after {
    content: none;
  }
  .p1 .section._1 .logo {
    margin-top: 40px;
  }
  .p1 {
    .lg_hide {
      opacity: 0;
    }
    .line {
      max-width: 70%;
      margin: auto;
    }
  }
  .p2 .icon_list .pic._2 {
    margin-top: -170px;
  }
  .white-pink {
    ul {
      margin: 0;
    }
  }
  .section {
    &._3 {
      padding-top: 0;
      margin-bottom: 0px;
    }
    &._2 {
      .hidden_planshet {
        .blue_line {
          margin-bottom: 10px;
        }
        ._flex {
          align-items: start;
          padding-bottom: 20px;
        }
      }
      .info {
        display: none;
      }
      .icon_log {
        max-width: 40px;
        margin-right: 20px;
      }
    }
    &._6 {
      margin-bottom: 20px;
    }
  }
}
@media screen and(min-width: 993px) {
  
  .lib_ttl .show_lib {
    font-size: 20px;
    line-height: 20px;
}
  .lg_hide_imp{
    display: none;
  }
}

@media screen and(max-width: 992px) {
  .section .right img {
    margin-left: 0px;
}
  .right {
    img {
      margin-left: 0px;
    }}
  .grafik_reverse{
    display: flex;
    flex-direction: column-reverse;
  }
  .section._3 .prep {
    height: 240px;}
  .section._3 .prep img {
    max-width: 265px;
}
  .section._1 {
    padding-bottom: 30px;
}
  .section._1 .right img {
    max-width: 430px;
    margin-left: 0px;
}
  .section._2 h2 {
    margin-top: 10px;
   margin-bottom: 10px;
}
  .section._2 .b2 p {
    padding-top: 15px;
    font-size: 24px;
    line-height: 30px;
   padding-bottom: 15px;
}
.section._2 .b2 {
  margin-bottom: 20px;
  height: auto;
}
  .sld .b_text {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 5px;
}
  .sld .text_p {
    font-size: 16px;
    line-height: 20px;
}
  .p1 .section._7 .libr {
    margin-top: 0px;
}
  .p4.p3.p2.p1 .section._2 {
    margin-bottom: 10px;
}
  .p1 .section._5 .rose_block {
    margin-top: 30px;
}
  .p3.p2.p1 {
    .section {
      &._2 {
        margin-bottom: 30px;
      }
    }
  }
  .p1 .section._5 .rose_block {
    margin-bottom: 10px;
}
  .p1 .section._3 {
    padding-top: 10px;
}
  .p1 .section._1 .block_inf._1 {
    height: auto;
}
.p1 .section._1 .block_inf._2 {
  height: auto;
}
  h1 {
    padding-top: 56px;
}
  .p1 .section._1 {
    padding-bottom: 20px;
}
  .p1 .section._4 .b_inf p {
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
}
  .bar_index{
    margin-bottom: 40px;
  }
  .p1 .section._1 .icon_list {
    margin-top: 0;
    margin-bottom: 0px;
}
  .p1 .section._1 .icon_list .list_icon {
  margin-bottom: 0;
}
  .list{
    width: 100%;
    display: flex;
    align-items: center;
    .img {
      margin-right: 10px;
      padding: 5px;
      max-width: 15%;
    }
  }
  
  .p3.p2.p1 .section._1 .block_inf._5 {
    margin-top: 20px;
}
  .p1 .section._3 .line_amoxi {
    margin-top: 0%;
}
  .p1 .section._5 .rose_block h2 {
    text-align: left;
    margin-bottom: 0px;
}
  
    ._m_bot{
        margin-bottom: 0;
    }
    ._mar_star{
        margin-top: 0;

    }
    .p1 .section._1 .img_1 img {
        width: 80%;
        margin: auto;
    }
    .p1 .section._1{
        .b_text{
            font-size: 16px;
            line-height: 17px;
        }
    }
    
  .mb_hide {
    display: none;
  }

  .nav_bottom {
    margin-top: 20px;
  }
  .p2.p1 .section._2 .sposob_v2 .text p {
    text-align: left;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
}
  h1 {
    font-size: 30px !important;
    line-height: 36px !important;
    sup {
      font-size: 20px !important;
      line-height: 20px !important;
    }
  }
  h2 {
    font-size: 30px !important;
    line-height: 32px !important;
    sup {
      font-size: 15px !important;
      line-height: 15px !important;
    }
  }
  .p1 .section._1 .block_inf._2 .btn {
    margin-top: 10px;
}
  .p2.p1 {
    .section {
      &._1 {
        .block_inf{
          &._3 {
            margin-top: 20px;
          }
        }
      }
    
    }
  }
  .p1 .section._1 .block_inf {
    &._1,
    &._2 {
      margin-left: 0;
      margin-right: 0;
      margin-top: 20px;
      ._flex {
        display: block;
      }
    }
    &._3 {
      
      .text {
        margin-left: 0 !important;
      }
    }

    .ttl {
      font-size: 32px;
    }
    p {
      font-size: 18px;
    }
  }

  .b_text {
    font-size: 20px;
    line-height: 20px;
    padding-bottom: 8px;
  }
  .text_p {
    font-size: 14px;
    line-height: 14px;
  }
  .pic {
    img {
      width: 50px;
    }
    padding-left: 7px !important;
    padding-right: 7px !important;
    &._1 {
      margin-top: -300px !important;
    }
    &._3 {
      margin-top: -200px !important;
    }
  }
  .p1 {
    .section {
      &._2 {
        padding-bottom: 20px;
        h2 {
          margin-top: 0;
        }
        p {
          margin-top: 0;
        }
        img {
          max-width: 200px;
        }
        .sposob {
          display: flex;
          align-items: center;
          &._spoon {
            flex-direction: row-reverse;
            img {
              margin-left: 30px;
            }
          }
          &._drink {
            img {
              margin-right: 20px;
            }
          }
        }
        .anim {
          display: none;
        }
        .three_steps {
          min-width: 80%;
          margin: auto;
          margin-top: 25px;
        }
      }
      &._3 {
        margin-bottom: 20px;
        .b_info {
          .b_text {
            font-size: 20px;
            line-height: 21px;
          }
          text {
            font-size: 16px;
            line-height: 17px;
          }
        }
      }
    

      &._5 {
        .packs {
          display: none;
        }
        .packs_mob {
          width: 80%;
          display:none;
        }
        .rose_block {
          img {
            &._left {
              margin-top: 30%;
            }
          }
          .inf {
            margin-top: 12px;
            p {
              font-size: 20px;
            }
            .li {
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  .b_info {
    display: flex;
    flex-direction: row;

    text-align: left !important;
    .box_text {
      margin-left: 20px;
    }
  }
  .content {
    padding: 24px;
  }
  .cont {
    display: flex;
    .box {
      margin-right: 40px;
    }
  }

  .footer_2 {
    p {
      font-size: 18px !important;
      line-height: 18px !important;
    }
  }
}
@media (max-width: 820px) {
  .p4.p3.p2.p1 .section._1 .block_inf {
    height: 482px;
}
  .popup_p1_2.p_3,
  .popup_p1_2.p_4 {
    max-width: 90%;
    height: auto;
    padding: 30px;
    .white-pink {
      li {
        font-weight: bold;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #616161;
        margin-bottom: 12px;
      }
    }
    .buton {
      border: 1px solid #ff2c64;
      box-sizing: border-box;
      border-radius: 18px;
      text-align: center;
      cursor: pointer;
      p {
        padding: 3px;
        color: var(--color-red);
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
      }
    }
    label {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #ff2c64;
      margin-bottom: 47px;
    }
    .b_text {
      font-style: normal;
      font-size: 18px;
      line-height: 20px;
      color: #616161;
      padding-top: 0px;
      padding-bottom: 0px;
      span {
        color: #1f1f1f;
      }
    }
    .ttl {
      font-family: PT Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 36px;
      color: #616161;
    }
  }
  .popup_p0_1,
  .popup_p1_1,
  .popup_p1_2,
  .popup_p2_1 {
    max-width: 90%;
    height: auto;
    padding: 30px;
    .white-pink {
      li {
        font-weight: bold;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #616161;
        margin-bottom: 12px;
      }
    }
    .buton {
      border: 1px solid #ff2c64;
      box-sizing: border-box;
      border-radius: 18px;
      text-align: center;
      cursor: pointer;
      p {
        padding: 3px;
        color: var(--color-red);
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
      }
    }
    label {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #ff2c64;
      margin-bottom: 47px;
    }
    .b_text {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      color: #616161;
      padding-top: 20px;
      padding-bottom: 25px;
      span {
        color: #1f1f1f;
      }
    }
    .ttl {
      font-family: PT Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 36px;
      color: #616161;
    }
  }
  .popup_p1_2,
  .popup_p2_1 {
    .b_text {
      padding: 0;
    }
    .popup_table_1 {
      margin-bottom: 0;
    }
  }
  .popup_p2_1 {
    .s_text {
      font-size: 16px;
      line-height: 24px;
    }
    .nose {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}
@media screen and(max-width: 780px) {
  
  .p2.p1 .section._4 {
    padding-top: 0px;
    margin-bottom: 0px;
}
  .p1 .section._5 .rose_block {
    padding: 10px 30px 25px;
}
  .m-w {
    max-width: 100%;
  }
  .m_text {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
}
  .section._6 h2 {
    margin-top: 20px;
}
  .slider .slick-arrow.slick-prev {
    left: -20px;
    max-width: 30px;
  }
  .slider .slick-arrow.slick-next {
    right: -20px;
    max-width: 30px;
  }
  .section {
    &._1 {
      padding-bottom: 30px;
      .left {
        max-width: 50%;
      }
      .right {
        max-width: 50%;
      }
      .rus_logo {
        max-width: 80px;
        bottom: 0;
      }
      .right {
        img {
          max-width: 300px;
        }
      }
    }
    &._2 {
      h2 {
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .b2 {
        p {
          font-size: 26px;
        }
      }
    }
  }
  .pic {
    padding-left: 0px !important;
    padding-right: 0px !important;
    &._1 {
      margin-top: -240px !important;
    }
    &._3 {
      margin-top: -160px !important;
    }
  }
}
@media screen and(max-width: 768px) {
  .button-fixed {
    top: 10%;
    left: 20px;
    padding: 10px 20px;
}
  .p4.p3.p2.p1 .section._1 .block_inf {
    height: auto;
}
  .p3.p2.p1 .section._1 .block_inf {
    height: auto;
}}
@media screen and(max-width: 690px) {
  .button-fixed {
    top: 8%;
}
  .p1 .section._3 .b_info .text {
    font-size: 16px;
    line-height: 17px;
}
  .p1 .section._4 .text {
    font-size: 16px;
    line-height: 17px;
  }
    .p1 .icon_list .pic._2 {
        margin-top: -70px;
    }
  h1 {
    font-size: 40px !important;
    line-height: 46px !important;
  }
  .section {
    &._1 {
      padding-bottom: 30px;
      .left {
        max-width: 50%;
      }
      .right {
        max-width: 50%;
      }
      .rus_logo {
        max-width: 80px;
        bottom: 0;
      }
      .right {
        img {
          max-width: 250px;
        }
      }
    }
  }
  .pic {
    padding-left: 0px !important;
    padding-right: 0px !important;
    &._1 {
      margin-top: -240px !important;
    }
    &._3 {
      margin-top: -160px !important;
    }
  }
}
@media screen and(max-width: 640px) {
  
  .p3.p2.p1 .section._1 ._flex p {
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
}
  .p1 {
    .section {
      &._4 {
        .b_inf {
          padding: 25px;
          p {
            font-weight: bold;
            font-size: 20px;
            line-height: 22px;
            color: #ffffff;
          }
        }
      }
      &._1 {
        .block_inf {
          &._1 {
            p {
              line-height: 24px;
            }
          }
          .ttl {
            font-size: 22px;
            margin-bottom: 0px;
          }
        }
      }
      &._2 {
        h1 {
          padding-top: 20px;
        }
      }
    }
  }
  .section {
    &._1 {
      padding-bottom: 30px;
      .left {
        max-width: 50%;
      }
      .right {
        max-width: 50%;
      }
      .rus_logo {
        max-width: 80px;
        bottom: 0;
      }
      .right {
        img {
          max-width: 200px;
        }
      }
    }
    &._2 {
      .b2 {
        height: 204px;
      }
    }
  }

  h1 {
    font-size: 30px !important;
    line-height: 30px !important;
    sup {
      font-size: 18px !important;
      line-height: 19px !important;
    }
  }
  h2 {
    font-size: 24px !important;
    line-height: 25px !important;
    sup {
      font-size: 14px !important;
      line-height: 15px !important;
    }
  }
  .pic {
    img {
      width: 38px;
    }

    &._1 {
      margin-top: -180px !important;
    }
    &._3 {
      margin-top: -160px !important;
    }
  }
}
@media screen and(max-width: 590px) {
  .sld .b_text {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 0px;
}
  .sld .text_p {
    font-size: 14px;
    line-height: 20px;
}
  .under_star {
    font-size: 10px;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .p1 {
    .section {
      &._1 {
        .block_inf {
          &._2 {
            .btn {
              margin-top: 20px;
              width: 139px;
              height: 48px;
              padding: 9px 18px;
            }
          }
        }
      }
    }
  }
  .section {
    &._1 {
      padding-bottom: 30px;
      .left {
        max-width: 50%;
      }
      .right {
        max-width: 50%;
      }
      .rus_logo {
        max-width: 80px;
        bottom: 0;
      }
      .right {
        img {
          margin-left: 10px;
          max-width: 240px;
        }
      }
    }
  }

  h1 {
    font-size: 30px !important;
    line-height: 30px !important;
    sup {
      font-size: 15px !important;
      line-height: 20px !important;
    }
  }
  h2 {
    font-size: 20px !important;
    line-height: 22px !important;
    sup {
      font-size: 14px !important;
      line-height: 15px !important;
    }
  }
  .pic {
    img {
      width: 38px;
    }

    &._1 {
      margin-top: -130px !important;
    }
    &._3 {
      margin-top: -100px !important;
    }
  }
}

@media screen and(max-width: 540px) {

  .b_text {
    font-size: 16px;
  }
}
@media (max-width: 510px) {
  .slick-list {
    max-width: 80%;
    margin: auto;
  }
  .slider .slick-arrow.slick-prev {
    left: 0;
  }
  .slider .slick-arrow.slick-next {
    right: 0;
  }
}
@media screen and(max-width: 500px) {
  .button-fixed {
    top: 7%;
    left: 10px;
    padding: 4px 15px;
}
  .popup_p0_1 {
    top: 5%;
}
  .logo {
    a {
      img {
        margin-top: 30px;
      }
    }
  }
  .p1 {
    .logo {
      a {
        img {
          margin-top: 0px;
        }
      }
    }
  }
  .medWarn__content {
    margin-top: 10px;
}
  .p1 .section._7 .footer_2 {
    margin-top: 20px;
    margin-bottom: 30px;
}
  .p1 .section._7 .line_border {
    margin-top: 20px;
    margin-bottom: 0px;
}
  .p1 .section._7 .footer {
    margin-top: 20px;
}
  .p1 .section._4 .block_info .btn {
    margin-top: 20px;
    margin-bottom: 20px;
}
  .p1 .section._4 {
    padding-top: 0px;
    border-radius: 20px;
}
  .sld .text_p {
    font-size: 14px;
    line-height: 20px;
}
  .sld .b_text {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
}
  .sld .desc {
    text-align: left;
    height: auto;
    padding: 20px;
    padding-bottom: 0;
}
.popup_p0_1 .ttl, .popup_p1_1 .ttl, .popup_p1_2 .ttl, .popup_p2_1 .ttl {
max-width: 90%;
}
  .popup_p1_2.p_3,
  .popup_p1_2.p_4 {
    .pink_line {
      margin-bottom: 10px;
    }
    height: auto;
    padding: 10px;
    background-position-y: bottom;

    .buton {
      p {
        padding: 3px;
        color: var(--color-red);
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;
      }
    }
    label {
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 25px;
    }
    .b_text {
      font-size: 14px;
      line-height: 14px;
      padding-top: 0px;
      padding-bottom: 0px;
      span {
        color: #1f1f1f;
      }
    }
    .ttl {
      font-size: 18px;
      line-height: 19px;
    }
  }
  .white-pink {
    li {
      font-size: 14px;
      line-height: 14px;
      color: #616161;
      margin-bottom: 12px;
    }
  }
  .popup_p0_1,
  .popup_p1_1,
  .popup_p1_2,
  .popup_p2_1 {
    max-width: 90%;
    .pink_line {
      margin-bottom: 10px;
    }
    height: auto;
    padding: 10px;
    background-position-y: bottom;

    .buton {
      p {
        padding: 3px 7px;
        color: var(--color-red);
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 10px;
        text-transform: uppercase;
      }
    }
    label {
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 25px;
    }
    .b_text {
      font-size: 14px;
      line-height: 24px;
      padding-top: 20px;
      padding-bottom: 21px;
      span {
        color: #1f1f1f;
      }
    }
    .ttl {
      font-size: 18px;
      line-height: 19px;
    }
  }
  .popup_p1_2,
  .popup_p2_1,
  .popup_p1_1 {
    padding-top: 25px;
    padding-bottom: 10px;
    .nose {
      max-width: 20px;
    }
    .s_text {
      font-size: 16px;
      line-height: 16px;
    }
    .b_text {
      font-size: 14px;
      padding-top: 0px;
      padding-bottom: 10px;
    }
    .blue_line {
      margin-top: 5px;
    }
    .popup_table_1 {
      margin-top: 16px;
      margin-bottom: 0;
    }
  }
  .popup_p1_1 .nose {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .popup_p1_2 {
    .ttl {
      margin-bottom: 10px;
    }
  }
  .logo_g {
    max-width: 40px;
  }
  .mfp-content {
    .pink_line {
      padding: 0;
    }
    .white-pink .mfp-close {
      right: 5px;
      top: 5px;
    }
  }
    .p1 .section._1{
        .b_text{
            font-size: 16px;
            line-height: 16px;
        }
    }
  .p1 {
    .section {
      &._5 {
        .packs_mob {
          width: 100%;
        }
        .rose_block {
          .rose_wave {
            margin-top: 5px;
            margin-bottom: 5px;
            img {
              width: 100%;
            }
          }
          img {
            &._left {
              margin-top: 100%;
            }
          }
          .inf {
            margin-top: 10px;
            p {
              font-size: 14px;
              line-height: 14px;
            }
            .li {
              font-size: 14px;
              line-height: 18px;
              &::before {
                top: 5px;
              }
            }
          }
        }
      }
      &._4 {
        .b_inf {
          padding: 15px;
          p {
            font-weight: bold;
            font-size: 14px;
            line-height: 22px;
            color: #ffffff;
          }
        }
      }
      &._1 {
        .block_inf {
          &._2 {
            .btn {
              margin-top: 20px;
              width: 109px;
              height: 28px;
              padding: 9px 18px;
            }
          }
          p {
            font-size: 16px;
            line-height: 16px;
          }
          &._1 {
            p {
              font-size: 16px;
              line-height: 16px;
            }
          }
          .ttl {
            font-size: 18px;
            line-height: 24px;
            padding-bottom: 10px;
          }
        }
      }
      &._2 {
        h1 {
          padding-top: 20px;
        }
      }
    }
  }
  .nav {
    font-family: PT Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
  }
  .btn {
    width: 109px;
    height: 28px;
    padding: 9px 18px;
    p {
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.04em;
    }
  }
  .content {
    padding: 10px;
  }

  .text_p {
    font-size: 14px;
    line-height: 14px;
  }
  .b_text {
    font-size: 16px;
    line-height: 16px;
    padding-bottom: 3px;
  }
  .p1 {
    .section {
      &._1 {
        .p_info {
          font-family: PT Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 16px;
        }
      }
      &._2 {
        .three_steps {
          min-width: 300px;
        }
      }
      &._3 {
        h2 {
          text-align: center;
          margin-bottom: 10px;
        }
        .b_info {
          img {
            max-width: 40px;
          }
          .text {
            font-size: 14px;
            line-height: 14px;
          }
        }
      }
      &._4 {
        .text {
          font-size: 14px;
          line-height: 14px;
        }
      }
    }
  }
  .section {
    &._1 {
      padding-bottom: 30px;

      h1 {
        padding-top: 42px;
      }
      .logo {
        margin-top: 10px;
        max-width: 70px;
      }
      .left {
        max-width: 50%;
      }
      .right {
        max-width: 50%;
      }
      .rus_logo {
        max-width: 65px;
        bottom: 0;
      }
      .right {
        img {
          margin-left: 0px;
          max-width: 100%;
        }
      }
    }
    &._2 {
      .d-flex {
        p {
          font-size: 16px;
          line-height: 16px;
        }
      }
      .b2 {
        height: auto;
        max-width: 90%;
        margin: 12px auto;
        p {
          font-size: 16px;
          line-height: 16px;
          padding-top: 4px;
          padding-bottom: 5px;
        }
      }
    }
    &._3 {
      .b_info {
        .b_text {
          font-size: 14px !important;
          line-height: 14px !important;
        }
      }

      .prep {
        height: auto;
        margin-top: 60px;
        .btn {
          position: absolute;
          right: 15px;
          bottom: 20px;
        }
        .text {
          font-size: 14px;
          line-height: 14px;
          margin-top: 6px;
          margin-left: 15px;
          margin-bottom: 27px;
        }
        .ttl {
          font-size: 16px;
          line-height: 16px;
          margin-top: 13px;
          margin-left: 15px;
         
        }
        img {
          max-width: 136px;
        }
      }
    }
    &._4 {
      .text {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

  h1 {
    font-size: 24px !important;
    line-height: 30px !important;
    sup {
      font-size: 10px !important;
      line-height: 18px !important;
    }
  }
  h2 {
    font-size: 18px !important;
    line-height: 18px !important;
    sup {
      font-size: 10px !important;
      line-height: 12px !important;
    }
  }
  .pic {
    img {
      width: 38px;
    }

    &._1 {
      margin-top: -80px !important;
    }
    &._3 {
      margin-top: -70px !important;
    }
  }
  .p3.p2.p1 .section._1 {
    ._flex {
      p {
        font-size: 14px;
        line-height: 14px;
      }
    }
    .block_inf p {
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
    }
  }
  .btn {
    p {
      font-size: 12px !important;
      line-height: 12px !important;
    }
  }
  .btn.v2 {
    width: 267px;
    margin-top: 5px !important;
  }
  .section._7 {
    .b_text {
      font-size: 12px !important;
      line-height: 14px !important;
    }
    p {
      font-size: 12px;
      line-height: 14px;
    }
    .footer {
      img {
        max-width: 70px;
      }
    }
    .footer_2 {
      p {
        font-size: 12px !important;
        line-height: 14px !important;
      }
    }
  }

  .p2.p1 {
    .section {
      &._2 {
        .sposob_v2 {
          .rose_wave {
            img {
              max-width: 100%;
            }
          }
          .box {
            .icon {
              img {
                max-height: 50px;
              }
            }
            p {
              font-size: 14px;
              line-height: 15px;
            }
          }
          .text {
            h2 {
              text-align: center;
              max-width: 100%;
              margin-bottom: 10px;
              margin-top: 10px;
              font-size: 18px;
              line-height: 19px;
            }
            p {
              font-size: 17px;
              line-height: 18px;
              text-align: center;
            }
          }
        }
      }
      &._4 {
        .h1 {
          text-align: left !important;
          margin-bottom: 20px;
        }

        .block_info {
          h2 {
            margin-bottom: 10px;
          }
          .m-w {
            margin-top: 12px;
            font-size: 16px;

            line-height: 16px;
            text-align: left;
          }
        }
        .m_text {
          margin-top: 12px;
          font-size: 16px;

          line-height: 16px;
          text-align: left;
        }
        .tabl {
          margin-top: 10px;
        }
      }
    }
  }

  .lib_ttl .show_lib {
    padding-top: 0;
    &:after {
      top: 1px !important;
      width: 8px;
      height: 8px;
      right: -12px;
    }
  }
}
@media (max-width: 425px) {
  .button-fixed {
    top: 7.5%;
  }
  .section {
    &._1 {
      .rus_logo {
        max-width: 55px;
      }
    }
  }
}
@media (max-width: 375px) {
  .section {
    &._1 {
      .rus_logo {
        max-width: 45px;
      }
    }
  }
}
@media (max-width: 325px) {
  .section {
    &._1 {
      h1 {
        padding-top: 12px;
        font-size: 21px !important;
      }
      .rus_logo {
        max-width: 35px;
      }
    }
  }
}

.prep {
  &:hover {
    transition: 0.5s;
    transform: scale(1.05);
  }
}
.b_info:hover {
  transition: 0.5s;
  transform: scale(1.1);
}
.box_anim:hover {
  transition: 0.5s;
  transform: scale(1.1);
}
@media (max-width: 390px) {
  .section {
    ._3 {
      .prep {
        .ttl {
          &._mob_top {
            margin-top: 6px;
          }
        }
      }
    }
  }
 
}
@media screen and (min-width: 1000px) {
  .hidden_planshet {
    display: none;
  }
}
